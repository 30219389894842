import { Button, FormControl, Input, InputGroup, Stack, Text } from "@chakra-ui/react";
import { EMAIL_RULES } from "../../constants";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { joinWaitlist } from "../../api/commands";
import { reportError } from "@lib/utils/reportError";
import { useAmplitude } from "../../hooks/utils/useAmplitude";
import { useNotifications } from "../../hooks/utils/useNotifications";
import React, { useState } from "react";

interface FormData {
  email: string;
}

export const WaitlistForm: React.FunctionComponent = () => {
  const methods = useForm<FormData>({ defaultValues: { email: "" } });
  const [loading, setLoading] = useState<boolean>(false);
  const [joined, setJoined] = useState<boolean>(false);
  const [addNotification] = useNotifications();
  const { logEvent, events } = useAmplitude();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setLoading(true);

      const result = await joinWaitlist({ email: data.email });

      if (result.result === "error") {
        throw result.message;
      } else {
        setJoined(true);
        logEvent(events.waitlist.submit);
      }
    } catch (error) {
      reportError(error);
      addNotification({ type: "error", message: "Failed to register for waitlist", autohide: true });
    } finally {
      setLoading(false);
    }
  };

  if (joined) {
    return (
      <Text>
        Thanks! We{"'"}ll be in touch when it{"'"}s ready.
      </Text>
    );
  }

  return (
    <>
      <Stack
        direction={{ base: "column", md: "row" }}
        as={"form"}
        id="waitlist"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        spacing={"12px"}
      >
        <FormProvider {...methods}>
          <FormControl isInvalid={!!methods.formState.errors.email}>
            <InputGroup>
              <Input
                borderWidth={1}
                color={"gray.800"}
                _placeholder={{
                  color: "gray.400",
                }}
                id={"email"}
                type={"email"}
                required
                placeholder={"Your email"}
                aria-label={"Your email"}
                disabled={loading}
                {...methods.register("email", EMAIL_RULES)}
              />
            </InputGroup>
          </FormControl>
          <FormControl w={{ base: "100%", md: "40%" }}>
            <Button
              type="submit"
              bg={"#3B3A78"}
              color={"white"}
              w="100%"
              _hover={{
                bg: "blue.700",
              }}
              isLoading={loading}
            >
              Join
            </Button>
          </FormControl>
        </FormProvider>
      </Stack>
    </>
  );
};
